import { Helmet } from "react-helmet";
import faviconFindCar from "../../../../assets/img/MYCG_V1/favicon.ico";
import faviconLumpSum from "../../../../assets/img/favicon-lumpsum.ico";
import faviconPcp from "../../../../assets/img/favicon-pcp.ico";
import faviconSmartPcp from "../../../../assets/img/favicon-smartpcp.ico";

const HelmetBasedOnDomain = () => {
  const domainName = window.location.hostname;
  let favicon;
  let brandName;

  if (
    domainName == "thepcpguys.co.uk" ||
    domainName == "dev.thepcpguys.co.uk" ||
    domainName == "pre.thepcpguys.co.uk"
  ) {
    favicon = faviconPcp;
    brandName = "My Claims Group | thepcpguys.co.uk";
  } else if (
    domainName === "smartpcp.lumpsumrefunds.com" ||
    domainName === "dev.smartpcp.lumpsumrefunds.com" ||
    domainName === "pre.smartpcp.lumpsumrefunds.com"
  ) {
    favicon = faviconLumpSum;
    brandName = "My Claims Group | smartpcp.lumpsumrefunds.com";
  } else if (
    domainName === "smartpcp.co.uk" ||
    domainName === "dev.smartpcp.co.uk" ||
    domainName === "pre.smartpcp.co.uk"
  ) {
    favicon = faviconSmartPcp;
    brandName = "My Claims Group | smartpcp.co.uk";
  } else {
    favicon = faviconFindCar;
    brandName = "My Claims Group | findcarfinance.online";
  }

  return (
    <Helmet>
      <link rel="icon" href={favicon} />
      <title>{brandName}</title>
    </Helmet>
  );
};

export default HelmetBasedOnDomain;
