import React, { useState, Suspense } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import HomePage from "./Components/Pages/HomePage";
import { RedirectProvider } from "./Contexts/RedirectContext";
import "./App.scss";
// import AdtopiaLoadDynamic from "./Utility/AdtopiaLoadDynamic";

// import EnvDocView from "./Components/Pages/MYCG_V1/EnvDocView";
import HelmetBasedOnDomain from "./Components/Includes/Layouts/Common/HelmetBasedOnDomain";

const MYCG_V1 = React.lazy(() => import("./Components/Pages/Splits/MYCG_V1"));

const MycgV1PersonalDetails = React.lazy(() =>
  import("./Components/Pages/MYCG_V1/MycgV1PersonalDetails")
);

const MycgV1Signature = React.lazy(() =>
  import("./Components/Pages/MYCG_V1/MycgV1Signature")
);
const MycgV1IdUpload = React.lazy(() =>
  import("./Components/Pages/MYCG_V1/MycgV1IdUpload")
);
const MycgV1CarRegistration = React.lazy(() =>
  import("./Components/Pages/MYCG_V1/MycgV1CarRegistration")
);
// const MycgV1Lenders = React.lazy(() =>
//   import("./Components/Pages/MYCG_V1/MycgV1Lenders")
// );

const MycgV1LendersComponentSelection = React.lazy(() =>
  import("./Components/Pages/MYCG_V1/MycgV1LendersComponentSelection")
);

const MycgV1EligibleLenders = React.lazy(() =>
  import("./Components/Pages/MYCG_V1/MycgV1EligibleLenders")
);

const FbPixel = React.lazy(() => import("./Components/Pages/FbPixel"));
const MycgV1Thankyou = React.lazy(() =>
  import("./Components/Pages/MYCG_V1/MycgV1Thankyou")
);
// const MycgV1CreditAnalysis = React.lazy(() =>
//   import("./Components/Pages/MYCG_V1/MycgV1CreditAnalysis")
// );

const MycgV1CreditAnalysisLoader = React.lazy(() =>
  import("./Components/Pages/MYCG_V1/MycgV1CreditAnalysisLoader")
);

const Loader = React.lazy(() =>
  import("./Components/Includes/Layouts/Loader/Loader")
);

const NoWinNoFee = React.lazy(() =>
  import("./Components/Includes/Layouts/Footer/NoWinNoFee")
);
const ComplaintsPolicy = React.lazy(() =>
  import("./Components/Includes/Layouts/Footer/ComplaintsPolicy")
);

const EditUserDetailsPage = React.lazy(() =>
  import("./Components/Pages/MYCG_V1/EditUserDetailsPage")
);

// MYCG-V1B
const MYCG_V1B = React.lazy(() => import("./Components/Pages/Splits/MYCG_V1B"));

const MycgV1BPersonalDetails = React.lazy(() =>
  import("./Components/Pages/MYCG_V1B/MycgV1BPersonalDetails")
);

const MycgV1BSignature = React.lazy(() =>
  import("./Components/Pages/MYCG_V1B/MycgV1BSignature")
);

// mycg-v1C
const MYCG_V1C = React.lazy(() => import("./Components/Pages/Splits/MYCG_V1C"));

const MycgV1CPersonalDetails = React.lazy(() =>
  import("./Components/Pages/MYCG_V1C/MycgV1CPersonalDetails")
);

const MycgV1CSignature = React.lazy(() =>
  import("./Components/Pages/MYCG_V1C/MycgV1CSignature")
);

const MycgV1CLenders = React.lazy(() =>
  import("./Components/Pages/MYCG_V1C/MycgV1CLenders")
);

const MycgV1CCreditAnalysisLoader = React.lazy(() =>
  import("./Components/Pages/MYCG_V1C/MycgV1CCreditAnalysisLoader")
);

// MYCG_OBV1
const MYCG_OBV1 = React.lazy(() =>
  import("./Components/Pages/Splits/MYCG_OBV1")
);

// MYCG_OBV1C
const MYCG_OBV1C = React.lazy(() =>
  import("./Components/Pages/Splits/MYCG_OBV1C")
);

// followup
const Followup = React.lazy(() =>
  import("./Components/Pages/Followup/Followup")
);

const FollowupMycgV1Signature = React.lazy(() =>
  import("./Components/Pages/Followup/MYCG_V1/FollowupMycgV1Signature")
);

// mycg_v1b
const FollowupMycgV1BSignature = React.lazy(() =>
  import("./Components/Pages/Followup/MYCG_V1B/FollowupMycgV1Signature")
);

const FollowupMycgV1IdUpload = React.lazy(() =>
  import("./Components/Pages/Followup/MYCG_V1/FollowupMycgV1IdUpload")
);
const FollowupMycgV1CarRegistration = React.lazy(() =>
  import("./Components/Pages/Followup/MYCG_V1/FollowupMycgV1CarRegistration")
);
const FollowupMycgV1Lenders = React.lazy(() =>
  import("./Components/Pages/Followup/MYCG_V1/FollowupMycgV1Lenders")
);

// const FollowupMycgV1CreditAnalysis = React.lazy(() =>
//   import("./Components/Pages/Followup/MYCG_V1/FollowupMycgV1CreditAnalysis")
// );

const FollowupMycgV1CreditAnalysisLoader = React.lazy(() =>
  import(
    "./Components/Pages/Followup/MYCG_V1/FollowupMycgV1CreditAnalysisLoader"
  )
);

const FollowupMycgV1LendersComponentSelection = React.lazy(() =>
  import(
    "./Components/Pages/Followup/MYCG_V1/FollowupMycgV1LendersComponentSelection"
  )
);

const FollowupMycgV1EligibleLenders = React.lazy(() =>
  import("./Components/Pages/Followup/MYCG_V1/FollowupMycgV1EligibleLenders")
);

const FlpEditUserDetailsPage = React.lazy(() =>
  import("./Components/Pages/Followup/FlpEditUserDetailsPage")
);

// mycg-v1c followup
const FollowupMycgV1CSignature = React.lazy(() =>
  import("./Components/Pages/Followup/MYCG_V1C/FollowupMycgV1Signature")
);

const FollowupMycgV1CLenders = React.lazy(() =>
  import(
    "./Components/Pages/Followup/MYCG_V1C/FollowupMycgV1LendersCreditFailed"
  )
);

const FollowupMycgV1CCreditAnalysisLoader = React.lazy(() =>
  import(
    "./Components/Pages/Followup/MYCG_V1C/FollowupMycgV1CreditAnalysisLoader"
  )
);

const MycgIDdUploadV2 = React.lazy(() =>
  import("./Components/Pages/MYCG_V1/MycgIDdUploadV2")
);
// MYCGV2

const MYCG_V2 = React.lazy(() => import("./Components/Pages/Splits/MYCG_V2"));

const MycgV2PersonalDetails = React.lazy(() =>
  import("./Components/Pages/MYCG_V2/MycgV2PersonalDetails")
);

const MycgV2Signature = React.lazy(() =>
  import("./Components/Pages/MYCG_V2/MycgV2Signature")
);

const MycgV2CreditAnalysisLoader = React.lazy(() =>
  import("./Components/Pages/MYCG_V2/MycgV2CreditAnalysisLoader")
);

const MycgV2LendersComponentSelection = React.lazy(() =>
  import("./Components/Pages/MYCG_V2/MycgV2LendersComponentSelection")
);

const MycgV2IdUpload = React.lazy(() =>
  import("./Components/Pages/MYCG_V2/MycgV2IdUpload")
);

const EditUserDetailsPageV2 = React.lazy(() =>
  import("./Components/Pages/MYCG_V2/EditUserDetailsPageV2")
);


const MycgV2CarRegistration = React.lazy(() =>
  import("./Components/Pages/MYCG_V2/MycgV2CarRegistration")
);

const MycgV2EligibleLenders = React.lazy(() =>
  import("./Components/Pages/MYCG_V2/MycgV2EligibleLenders")
);

const MycgV2Thankyou = React.lazy(() =>
  import("./Components/Pages/MYCG_V2/MycgV2Thankyou")
);

const Privacy = React.lazy(() =>
  import("./Components/Pages/Privacy")
);


// MYCGV3

const MYCG_V3 = React.lazy(() => import("./Components/Pages/Splits/MYCG_V3"));

const MycgV3PersonalDetails = React.lazy(() =>
  import("./Components/Pages/MYCG_V3/MycgV3PersonalDetails")
);

const MycgV3CreditAnalysisLoader = React.lazy(() =>
  import("./Components/Pages/MYCG_V3/MycgV3CreditAnalysisLoader")
);

const MycgV3LendersComponentSelection = React.lazy(() => import('./Components/Pages/MYCG_V3/MycgV3LendersComponentSelection'));

const MycgV3EligibleLenders = React.lazy(() =>
  import("./Components/Pages/MYCG_V3/MycgV3EligibleLenders")
);

const EditUserDetailsPageV3 = React.lazy(() =>
  import("./Components/Pages/MYCG_V3/EditUserDetailsPageV3")
);

const MycgV3CarRegistration = React.lazy(() =>
  import("./Components/Pages/MYCG_V3/MycgV3CarRegistration")
);

const MycgV3IdUpload = React.lazy(() =>
  import("./Components/Pages/MYCG_V3/MycgV3IdUpload")
);
const MycgV3IDdUploadV2 = React.lazy(() =>
  import("./Components/Pages/MYCG_V3/MycgV3IDdUploadV2")
);

const MycgV3Thankyou = React.lazy(() =>
  import("./Components/Pages/MYCG_V3/MycgV3Thankyou")
);


const FollowupIdUploadV2 = React.lazy(()=>
import("./Components/Pages/Followup/MYCG_V1/FollowupIdUploadV2")
);

//Followup Pending Id Upload
const FollowupID = React.lazy(() =>
  import("./Components/Pages/Followup/FollowupID")
);
const App = () => {
  const [visitorParameters] = useState({ visitor_parameters: [], data: [] });
  const [redirectUrl] = useState({ redirectUrl: "" });

  return (
    <>
      <HelmetBasedOnDomain />
      <VisitorParamsProvider value={{ visitorParameters }}>
        <RedirectProvider value={{ redirectUrl }}>
          <BrowserRouter>
            <Suspense
              fallback={<div style={{ color: "#FFFFFF" }}>Loading...</div>}
            >
              <Route exact path="/" component={HomePage} />
              <Route exact path="/MYCG_V1" component={MYCG_V1} />
              <Route
                exact
                path="/mycg-v1-personal-details"
                component={MycgV1PersonalDetails}
              />
              <Route exact path="/loader" component={Loader} />

              {/* <Route
                  exact
                  path="/mycg-v1-credit-analysis"
                  component={MycgV1CreditAnalysis}
                /> */}

              <Route
                exact
                path="/mycg-v1-credit-analysis"
                component={MycgV1CreditAnalysisLoader}
              />

              <Route
                exact
                path="/mycg-v1-signature"
                component={MycgV1Signature}
              />
              <Route
                exact
                path="/mycg-v1-lenders"
                component={MycgV1LendersComponentSelection}
              />

              <Route
                exact
                path="/mycg-v1-eligible-lenders"
                component={MycgV1EligibleLenders}
              />

              <Route
                exact
                path="/mycg-v1-edit-user-details"
                component={EditUserDetailsPage}
              />

              <Route
                exact
                path="/mycg-v1-car-registration"
                component={MycgV1CarRegistration}
              />
              <Route
                exact
                path="/mycg-v1-idupload"
                component={MycgV1IdUpload}
              />
              <Route
                exact
                path="/mycg-v1-Thankyou"
                component={MycgV1Thankyou}
              />

              {/* mycg-v1b */}
              <Route exact path="/MYCG_V1B" component={MYCG_V1B} />

              <Route
                exact
                path="/mycg-v1B-personal-details"
                component={MycgV1BPersonalDetails}
              />

              <Route
                exact
                path="/mycg-v1B-signature"
                component={MycgV1BSignature}
              />

              {/* mycg-v1c */}
              <Route exact path="/MYCG_V1C" component={MYCG_V1C} />

              <Route
                exact
                path="/mycg-v1C-personal-details"
                component={MycgV1CPersonalDetails}
              />

              <Route
                exact
                path="/mycg-v1C-lenders"
                component={MycgV1CLenders}
              />

              <Route
                exact
                path="/mycg-v1C-signature"
                component={MycgV1CSignature}
              />

              <Route
                exact
                path="/mycg-v1C-credit-analysis"
                component={MycgV1CCreditAnalysisLoader}
              />

              {/* mycg_obv1 */}
              <Route exact path="/MYCG_OBV1" component={MYCG_OBV1} />

              {/* mycg_obv1c */}
              <Route exact path="/MYCG_OBV1C" component={MYCG_OBV1C} />

              <Route exact path="/complaints" component={ComplaintsPolicy} />

              <Route exact path="/no-win-no-fee" component={NoWinNoFee} />

              <Route exact path={["/fbpixel"]} component={FbPixel} />
              {/* <Route
                exact
                path={["/:doc/:docType/:filename"]}
                component={EnvDocView}
              />
              <Route
                exact
                path={["/google", "/testing"]}
                component={AdtopiaLoadDynamic}
              /> */}

              {/* followup */}
              <Route exact path={"/followup"} component={Followup} />
              <Route
                exact
                path="/followup/mycg-v1-credit-analysis"
                component={FollowupMycgV1CreditAnalysisLoader}
              />

              <Route
                exact
                path="/followup/mycg-v1-signature"
                component={FollowupMycgV1Signature}
              />

              {/* mycg_v1b */}
              <Route
                exact
                path="/followup/mycg-v1B-signature"
                component={FollowupMycgV1BSignature}
              />

              <Route
                exact
                path="/followup/mycg-v1-lenders"
                component={FollowupMycgV1LendersComponentSelection}
              />

              <Route
                exact
                path="/followup/mycg-v1-eligible-lenders"
                component={FollowupMycgV1EligibleLenders}
              />

              <Route
                exact
                path="/followup/mycg-v1-edit-user-details"
                component={FlpEditUserDetailsPage}
              />

              <Route
                exact
                path="/followup/mycg-v1-car-registration"
                component={FollowupMycgV1CarRegistration}
              />
              <Route
                exact
                path="/followup/mycg-v1-idupload"
                component={FollowupMycgV1IdUpload}
              />

              {/* mycg-v1c  flp*/}
              <Route
                exact
                path="/followup/mycg-v1C-lenders"
                component={FollowupMycgV1CLenders}
              />

              <Route
                exact
                path="/followup/mycg-v1C-signature"
                component={FollowupMycgV1CSignature}
              />

              <Route
                exact
                path="/followup/mycg-v1C-credit-analysis"
                component={FollowupMycgV1CCreditAnalysisLoader}
              />

              <Route
                exact
                path="/mycg-v1-iduploadV2"
                component={MycgIDdUploadV2}
              />

              {/* MYCGV2 */}
              <Route exact path="/MYCG_V2" component={MYCG_V2} />
              <Route
                exact
                path="/mycg-v2-personal-details"
                component={MycgV2PersonalDetails}
              />

              <Route
                exact
                path="/mycg-v2-signature"
                component={MycgV2Signature}
              />
              <Route
                exact
                path="/mycg-v2-credit-analysis"
                component={MycgV2CreditAnalysisLoader}
              />
              <Route
                exact
                path="/mycg-v2-lenders"
                component={MycgV2LendersComponentSelection}
              />

              <Route
                exact
                path="/mycg-v2-idupload"
                component={MycgV2IdUpload}
              />

              <Route
                exact
                path="/mycg-v2-edit-user-details"
                component={EditUserDetailsPageV2}
              />
              <Route
                exact
                path="/mycg-v2-car-registration"
                component={MycgV2CarRegistration}
              />
              <Route
                exact
                path="/mycg-v2-eligible-lenders"
                component={MycgV2EligibleLenders}
              />

              <Route
                exact
                path="/mycg-v2-Thankyou"
                component={MycgV2Thankyou}
              />

              <Route
                exact
                path="/privacy-policy"
                component={Privacy}
              />

            <Route exact path="/fast-track-processing" component={MYCG_V1} />
            <Route exact path="/free-online-check" component={MYCG_V1} />
            <Route exact path="/5-star-rating" component={MYCG_V1} />
            <Route exact path="/register-your-refund-now" component={MYCG_V1} />
             
              {/* MYCGV3 */}
              <Route exact path="/MYCG_V3" component={MYCG_V3} />
              <Route
                exact
                path="/mycg-v3-personal-details"
                component={MycgV3PersonalDetails}
              />

              <Route
                exact
                path="/mycg-v3-credit-analysis"
                component={MycgV3CreditAnalysisLoader}
              />
              <Route
                exact
                path="/mycg-v3-lenders"
                component={MycgV3LendersComponentSelection}
              />
              <Route
                exact
                path="/mycg-v3-eligible-lenders"
                component={MycgV3EligibleLenders}
              />

              <Route
                exact
                path="/mycg-v3-edit-user-details"
                component={EditUserDetailsPageV3}
              />

              <Route
                exact
                path="/mycg-v3-car-registration"
                component={MycgV3CarRegistration}
              />
              <Route
                exact
                path="/mycg-v3-idupload"
                component={MycgV3IdUpload}
              />
              <Route
                exact
                path="/mycg-v3-Thankyou"
                component={MycgV3Thankyou}
              />
              <Route
                exact
                path="/mycg-v3-iduploadV2"
                component={MycgV3IDdUploadV2}
              />
               <Route
                exact
                path="/followup/mycg-v2-idupload"
                component={FollowupIdUploadV2}
              />
              <Route
                exact
                path="/followup-id"
                component={FollowupID}
              />
              

            </Suspense>
          </BrowserRouter>
        </RedirectProvider>
      </VisitorParamsProvider>
    </>
  );
};

export default App;
